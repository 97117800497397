body {
  margin: 0;
  font-family: "Cerebri Sans",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0070ff;
}

#basic-navbar-nav .navbar-nav a:hover{
  color: #6e84a3 !important;
}

#gateKeeper {
  background-color: #0070ff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#gateKeeper .gatekeeper-form-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

#gateKeeper .gatekeeper-aligner {
  display: flex;
  width: 60%;
  flex-direction: column;
  background-color: #fff;
  padding: 0 50px 50px;
}

#gateKeeper input {
  margin-bottom: 20px;
}

#navbar a {
  color: #fff;
}